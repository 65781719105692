@import "variable";
@import "mixin";

/* ======================================================================
 p_top
====================================================================== */
// メインビジュアル
.l_main_visual_top {
  position: relative;
  padding: 40px;
  &_banner {
    width: 600px;
    height: 195px;
    margin: 0 0 25px 0;
    padding: 17px 57px;
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    border-radius: 0 15px 0 0;
    background: $white;
    &:after {
      content: "";
      width: 168px;
      height: 100px;
      position: absolute;
      right: -100px;
      bottom: 0;
      background: url(#{$img_dir}/p_top/hd_obj.svg);
    }
  }
  &_ttl {
    margin: 0 0 8px 0;
  }
  &_txt {
    line-height: 1.7;
    font-size: 18px;
  }
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -145px;
    left: -145px;
    width: 427px;
    height: 427px;
    background: url(#{$img_dir}/common/hd_bg_y.svg);
  }
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    right: -81px;
    bottom: -147px;
    width: 305px;
    height: 305px;
    background: url(#{$img_dir}/common/hd_bg_g.svg);
  }
  @include tab {
    padding: 20px;
  }
  @include sp {
    height: fit-content;
    max-height: 560px;
    padding: $sp_side_padding $sp_side_padding 20px $sp_side_padding;
    &:before {
      top: -106px;
      left: -99px;
      width: 300px;
      height: 300px;
      background-size: contain;
    }
    &:after {
      right: -59px;
      bottom: -34px;
      width: 179px;
      height: 179px;
      background-size: contain;
    }
    &_banner {
      width: calc(100% - 20px);
      margin: 0;
      height: 210px;
      padding: 17px 0;
      left: 50%;
      transform: translate(-50%, 45%);
      border-radius: 10px;
      text-align: center;
      &:after {
        width: 110px;
        height: 60px;
        bottom: 100%;
        right: 50%;
        transform: translate(50%, 0);
        background-size: cover;
      }
    }
    &_ttl {
      margin: 4px 0 5px 0;
    }
    &_txt {
      font-size: 16px;
    }
    .u_fs_5 {
      font-size: 20px;
    }
  }
}

// cta
.cta_btn {
  position: fixed;
  bottom: 0;
  display: none;
  width: 100%;
  padding: 5px 10px 8px;
  background: rgba(255,255,255,0.8);
  z-index: 10;
  @include pc {
    display: none !important;
  }
  @include sp {
    display: flex;
    gap: 0 5px;
  }
  .c_cta_btn {
    margin: 0 auto;
    @include sp {
      flex: 0 0 50%;
      font-size: 16px;
      height: 57px;
    }
  }
}

.cta_hr {
  position: absolute;
  top: 100px;
  display: block;
  @include pc {
    display: none !important;
  }
}

// スライダー
.c_slider {
  position: relative;
  .slick-list {
    border-radius: 20px;
  }
  .slick-slide {
    img {
      width: 100%;
      object-fit: cover;
      object-position: left bottom;
    }
  }
  .slick-arrow {
    content: "";
    position: absolute;
    z-index: 1;
    top: calc(50% - 35px);
    width: 40px;
    height: 40px;
    background: url(#{$img_dir}/common/arrow.svg);
    color: transparent;
  }
  .slick-prev {
    left: 11px;
    transform: rotate(180deg);
  }
  .slick-next {
    right: 11px;
  }
  .slick-dots {
    display: flex;
    width: fit-content;
    margin: 16px auto 0 auto;
    li + li {
      margin: 0 0 0 10px;
    }
    li button {
      width: 10px;
      height: 10px;
      border-radius: 9999px;
      border: solid 1px $gray_7;
      color: transparent;
    }
    .slick-active button {
      border: solid 1px $bluegreen_1;
      background: $bluegreen_1;
    }
  }
  @include sp {
    .slick-slide {
      img {
        max-width: 345px;
        height: auto;
        margin: 0 auto;
        border-radius: 10px;
        object-position: center bottom;
        object-fit: contain;
      }
    }
    .slick-arrow {
      width: 30px;
      height: 30px;
      background-size: contain;
    }
    .slick-prev {
      left: 5px;
      top: calc(50% - 28px);
    }
    .slick-next {
      right: 5px;
      top: calc(50% - 28px);
    }
    .slick-dots {
      position: absolute;
      top: 108px;
      left: 10px;
      li button {
        border: none;
        background: $white;
        border: solid 1px $gray_7;
      }
    }
  }
}

// カードリスト
.p_top {
  .c_card {
    max-width: 320px;
    min-height: 425px;
    &_img {
      height: auto;
      border-radius: 20px;
    }
    @include sp {
      max-width: 100%;
      min-height: unset;
    }
  }
  .c_card_list_item {
    max-width: unset;
  }
}

// スグ得合宿速報
.c_section_latest_news {
  @include sp {
    margin: 80px 0 0 0;
  }
}

.c_latest_news {
  position: relative;
  padding: 35px 25px 30px 25px;
  border-radius: 30px;
  background: $yellow_1;
  &_ttl {
    padding: 0 0 25px 0;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    background-image: -webkit-linear-gradient(to top, $black 1px, transparent 1px);
    background-image: linear-gradient(to top, $black 1px, transparent 1px);
    background-size: 80px 52px;
    background-repeat: no-repeat;
    background-position: center;
    @include pc_tab {
      background-size: 80px 60px;
    }
  }
  &_list {
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 30px 0 0 0;
  }
  &_item {
    width: calc(50% - 15px);
    border: solid 1px $gray_4;
    border-radius: 20px;
    box-shadow: 2px 3px 1px 0px rgba(0, 0, 0, 0.082);
    background: $white;
  }
  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: -100px;
    top: -46px;
    width: 205px;
    height: 92px;
    background: url(#{$img_dir}/common/obj-6.svg);
    background-size: contain;
  }
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    right: -310px;
    top: -172px;
    width: 574px;
    height: 257px;
    background: url(#{$img_dir}/common/obj-4.svg);
    background-size: contain;
  }
  .u_fs_2 {
    display: inline-block;
  }
  @include sp {
    margin: 20px 0 0 0;
    padding: 30px 10px;
    border-radius: 10px;
    &_ttl {
      padding: 0 0 20px 0;
      font-size: 24px;
    }
    &_list {
      margin: 20px 0 0 0;
    }
    &_item {
      width: 100%;
      & + & {
        margin: 10px auto 0 auto;
      }
    }
    &:before {
      width: 152px;
      height: 68px;
      top: unset;
      bottom: -7px;
      left: -82px;
    }
    &:after {
      width: 418px;
      height: 187px;
      top: -119px;
      right: -246px;
    }
  }
}

// 選ばれる理由
.c_reason {
  position: relative;
  margin: 47px 0 0 0;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -146px;
    left: -60px;
    width: 175px;
    height: 173px;
    background: url(#{$img_dir}/common/obj-3.svg);
    background-size: contain;
  }
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 54%;
    right: -136px;
    width: 227px;
    height: 280px;
    background: url(#{$img_dir}/common/obj-2.svg);
    background-size: contain;
  }
  &_item {
    & + & {
      margin: 45px 0 0 0;
    }
  }
  @include sp {
    margin: 30px 0 0 0;
    &:before {
      width: 108px;
      height: 107px;
      top: -42px;
    }
    &:after {
      width: 180px;
      height: 222px;
      top: 30%;
      right: -112px;
    }
    &_item {
      & + & {
        margin: 40px 0 0 0;
      }
    }
  }
}

// 卒業生の声
.c_section_voice {
  position: relative;
  margin: 130px 0 0 -20px;
  width: calc(100% + 40px);
  &_item1 {
    position: absolute;
    z-index: 1;
    top: -101px;
    left: -56px;
  }
  &_item2 {
    position: absolute;
    z-index: 1;
    bottom: -25px;
    left: calc(50% - 103px);
  }
  &_item3 {
    position: absolute;
    z-index: 1;
    top: -118px;
    right: 0;
  }
  @include tab {
    margin: 130px 0 0 -10px;
    width: calc(100% + 20px);
  }
  @include sp {
    margin: 142px 0 0 0;
    padding: 0 0 10px 0;
    width: 100%;
    &_item1 {
      width: 140px;
      height: 140px;
      z-index: 0;
      top: -110px;
    }
    &_item2 {
      width: 151px;
      height: 68px;
      left: unset;
      right: -50px;
    }
    &_item3 {
      width: 77px;
      height: 53px;
      top: -120px;
    }
  }
}

.c_section_inlink {
  .c_card_list_2 {
    .c_voice_right_item {
      width: 35%;
      @include pc_tab {
        width: 40%;
        min-height: 129px;
      }
    }
  }
  .c_card_5 {
    @include pc_tab {
      height: 100%;
    }
    &_right {
      @include sp {
        width: 65%;
      }
      @include pc_tab {
        padding: 5px;
      }
    }
    &_ttl {
      @include sp {
        font-size: 20px;
      }
    }
    .c_btn_secondary {
      @include pc_tab {
        padding: 3.5px 10px;
      }
    }
  }
}

.c_voice {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 50px 0;
  border-radius: 10px;
  box-shadow: 10px 10px 0 0 rgba(0, 162, 154, 0.6);
  background: $white;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    width: calc(100vw - 220px);
    height: calc(100% + 100px);
    transform: translate(-50%, -50%);
    background: url(#{$img_dir}/p_top/bg.png);
    background-size: cover;
  }
  &_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    width: 47.5%;
    height: 370px;
    margin: 0 auto;
    padding: 80px 0;
  }
  &_txt {
    margin: 20px 0 0 0;
  }
  &_btn {
    margin: 30px 0 0 0;
  }
  &_right {
    width: 52.5%;
    max-width: 560px;
    border-radius: 0 10px 10px 0;
    overflow: hidden;
  }
  &_right_item {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @include tab {
    &:before {
      width: 100vw;
    }
  }
  @include sp {
    flex-direction: column-reverse;
    margin: 50px 0 0 0;
    box-shadow: 6px 11px 0 0 rgba(0, 162, 154, 0.6);
    &:before {
      width: 100vw;
      height: 200px;
      top: 20px;
      background: url(#{$img_dir}/p_top/sp/bg.png);
      background-size: cover;
    }
    &_left {
      width: 100%;
      height: auto;
      padding: 30px 0 50px 0;
    }
    &_txt {
      margin: 10px 0 0 0;
      text-align: center;
    }
    &_btn {
      margin: 20px 0 0 0;
    }
    &_right {
      width: 100%;
      max-width: unset;
      border-radius: 10px 10px 0 0;
    }
  }
}

// バナー
.c_bnr_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 60px 0 0 0;
  &_item {
    width: calc(50% - 12.5px);
    & + & {
      margin: 0 0 0 25px;
    }
  }
  &_img {
    width: 100%;
    object-fit: contain;
  }
  @include sp {
    flex-direction: column;
    margin: 30px 0 0 0;
    &_item {
      width: 100%;
      & + & {
        margin: 15px 0 0 0;
      }
    }
  }
}

// お知らせ
.c_info {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: calc(100% + 40px);
  margin: 50px 0 50px -20px;
  padding: 18px 30px;
  border: solid 1px $gray_4;
  border-radius: 20px;
  box-shadow: 2px 3px 1px 0px rgba(0, 0, 0, 0.082);
  background: $white;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -210px;
    left: -230px;
    width: 420px;
    height: 420px;
    background: url(#{$img_dir}/common/obj.svg);
    background-size: contain;
  }
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -180px;
    right: -328px;
    width: 574px;
    height: 256px;
    background: url(#{$img_dir}/common/obj-1.svg);
    background-size: contain;
  }
  &_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 15px 0 18px;
  }
  &_btn {
    width: 100%;
    margin: 30px 0 0 0;
  }
  &_right {
    flex-grow: 1;
    max-width: 620px;
  }
  &_item {
    & + & {
      border-top: solid 1px $gray_1;
    }
    @include pc_tab {
      &:hover {
        .c_info_txt {
          color: $bluegreen_1;
          text-decoration: underline;
          text-underline-position: under;
        }
        .c_info_txt._new:before {
          text-decoration: underline;
          text-underline-position: under;
          text-decoration-color: $pink_1;
        }
      }
    }
  }
  &_item_inner {
    display: flex;
    align-items: center;
    height: 66px;
    padding: 19.5px 0;
  }
  &_date {
    font-size: 16px;
    font-weight: 700;
  }
  &_cat {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 106px;
    min-width: 70px;
    margin: 0 10px 0 10px;
    padding: 2px 0;
    padding: 2px 10px;
    border: solid 1px $bluegreen_1;
    border-radius: 9999px;
    text-align: center;
    font-size: 14px;
    color: $bluegreen_1;
  }
  &_txt {
    display: flex;
    align-items: center;
  }
  &_txt._new:before {
    content: "NEW";
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 50px;
    height: 22px;
    margin: 0 5px 0 0;
    border-radius: 9999px;
    background: $pink_1;
    font-weight: 300;
    font-size: 14px;
    color: $white;
    text-decoration: none;
  }
  .c_ttl_primary {
    line-height: 145%;
  }
  @include sp {
    flex-direction: column;
    width: 100%;
    margin: 50px auto;
    padding: 30px 15px;
    border-radius: 10px;
    &:before {
      top: -180px;
      left: -160px;
      width: 300px;
      height: 300px;
    }
    &:after {
      bottom: -130px;
      right: -240px;
      width: 410px;
      height: 183px;
    }
    &_left {
      margin: 0 auto;
    }
    &_btn {
      width: fit-content;
      max-width: 100%;
      margin: 5px auto 0 auto;
    }
    &_right {
      margin: 5px 0 0 0;
    }
    &_item_inner {
      flex-wrap: wrap;
      height: auto;
    }
    &_txt {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      margin: 10px 0 0 0;
    }
    &_txt._new:before {
      display: inline;
      padding: 1px 10px;
    }
    .c_ttl_primary {
      line-height: 135%;
    }
    .c_ttl_primary_img {
      margin: 0 0 5px 0;
      width: 72px;
      height: 40px;
      object-fit: contain;
    }
  }
}
